// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
  //   Chakra color mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex alignItems="center" flexDirection="column">
      <Text
        color={textColor}
        fontSize="22px"
        mb="32px"
        fontWeight="700"
        lineHeight="100%"
      >
        Social Pin
      </Text>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
