import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CustomTable from './Table';

const users = [
  {
    name: 'John Doe',
    progress: '80%',
    quantity: 10,
    date: '2023-01-01',
  },
  {
    name: 'Jane Smith',
    progress: '50%',
    quantity: 20,
    date: '2023-01-02',
  },
  // Add more user data here
];

const columnHelper = createColumnHelper<typeof users[0]>();

const columns = [
  columnHelper.accessor('name', {
    header: () => <Text>NAME</Text>,
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
  columnHelper.accessor('progress', {
    header: () => <Text>PROGRESS</Text>,
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
  columnHelper.accessor('quantity', {
    header: () => <Text>QUANTITY</Text>,
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
  columnHelper.accessor('date', {
    header: () => <Text>DATE</Text>,
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
];

export default function AccountsPage() {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <CustomTable columns={columns} data={users} />
      </SimpleGrid>
    </Box>
  );
}